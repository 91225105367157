import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Fornecedor } from 'src/models/fornecedor';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

import Compressor from 'compressorjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-marcas',
    templateUrl: './marcas.component.html',
    styleUrls: ['./marcas.component.scss'],
})
export class MarcasComponent implements OnInit {
    mostraFormPush = false;
    marca: any = {
        ativo: true,
    };
    page: number;
    pageSize: number;
    quantidadeTotal: number;
    marcas: any[] = [];
    image: any;
    mostraFormMarcas: boolean;

    constructor(public api: ApiService, private spinner: NgxSpinnerService) {}

    ngOnInit() {
        this.obterMarcas();
    }

    obterMarcas(searching = false) {
        if (searching) {
            this.page = 1;
        }
        this.spinner.show();

        this.api.obterMarcas(this.page).subscribe(
            (res: {
                marcas: Array<any>;
                paginaAtual: number;
                quantidadeTotal: number;
                quantidadeTotalPagina: number;
            }) => {
                console.log(res);
                this.page = res.paginaAtual;
                this.pageSize = res.quantidadeTotalPagina;
                this.quantidadeTotal = res.quantidadeTotal;
                this.marcas = res.marcas.map(p => {
                    if (p.imagem != '') {
                        p.imagem = 'data:image/jpeg;base64,' + p.imagem;
                    }
                    return p;
                });
                this.spinner.hide();
            },
            null,
            () => this.spinner.hide(),
        );
    }

    async changeListener($event): Promise<void> {
        const base64: string = await this.readThis($event.target);
        this.image = base64;
        this.marca.imagem = base64.split(',')[1];
    }

    readThis(inputValue: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const file: File = inputValue.files[0];
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    const reader = new FileReader();
                    reader.readAsDataURL(result);
                    reader.onloadend = function() {
                        resolve(reader.result);
                    };
                },
                error(err) {
                    reject(err.message);
                },
            });
        });
    }

    isEmpty(obj) {
        return !obj || Object.keys(obj).length === 0;
    }

    handleEditBtn(marca) {
        console.log(marca);
        this.marca = marca;
        this.image = this.marca.imagem;
        this.abrirFormMarcas();
    }
    handleMarcasForm(form: NgForm) {
        const dados = this.marca;

        console.log(dados);

        if (this.marca.id) {
            this.atualizarMarca(form);
        }
    }

    // handleDeleteBtn

    handleDeleteBtn(marca) {
        Swal.fire({
            title: 'Você tem certeza?',
            text: 'Ao confirmar os dados não poderão ser recuperados!',
            type: 'question',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
        }).then(confirm => {
            if (confirm.value == true) {
                this.api.deletarMarca(marca).subscribe(
                    res => {
                        this.obterMarcas();
                        Swal.fire({
                            title: 'Sucesso!',
                            text: 'Marca Deletada com sucesso',
                            type: 'success',
                        });
                    },
                    err => {
                        Swal.fire({
                            title: 'Erro!',
                            text: 'Não foi possível deletar a marca',
                            type: 'error',
                        });
                    },
                );
            }
        });
    }

    atualizarMarca(form: NgForm) {
        this.marca.imagemUpload = this.image.split(',')[1];

        this.api.atualizarMarca(this.marca).subscribe(
            () => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Marca atualizada com sucesso',
                    type: 'success',
                }).then(() => {
                    this.obterMarcas();
                    this.fecharFormMarcas();
                    form.resetForm();
                    this.marca = {};
                });
            },
            err => {
                const errors = err.error.errors ? err.error.errors.concat('<br>') : '';
                Swal.fire({
                    title: 'Erro!',
                    html: 'Não foi possível atualizar a promoção<br>' + errors,
                    type: 'error',
                }).then(() => {});
            },
        );
    }

    abrirFormMarcas() {
        this.mostraFormMarcas = true;
    }
    fecharFormMarcas() {
        this.mostraFormMarcas = false;
    }
}
