import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Fornecedor } from 'src/models/fornecedor';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-lojas',
    templateUrl: './lojas.component.html',
    styleUrls: ['./lojas.component.scss'],
})
export class LojasComponent implements OnInit {
    lojasParceiras: Fornecedor[];
    lojasParceirasToList: Fornecedor[];
    closeResult: string;
    loja: any = {
        endereco: {},
        fornecedorBase: {},
        usuario: {},
    };
    modalOption: NgbModalOptions = {};
    errors: Array<string>;
    @ViewChild('content', { static: true }) content: ElementRef;
    @ViewChild('lojasForm', { static: false }) lojasForm: any;

    constructor(public apiService: ApiService, private modalService: NgbModal, public http: HttpClient) {}

    ngOnInit() {
        this.obterLojasParceiras();
        console.log(this.loja);
    }
    getResetedLoja() {
        return;
    }

    open(tipo = null) {
        if (tipo == 'novo') {
            this.loja = {
                endereco: {},
                fornecedorBase: {},
                usuario: {},
            };
        }
        this.modalOption.backdrop = 'static';
        this.modalOption.keyboard = false;
        this.modalOption.size = 'lg';
        this.modalService.open(this.content, this.modalOption).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    handleSearch(searchTerm: string) {
        this.lojasParceirasToList = this.lojasParceiras.filter(d => {
            return (
                d.nome.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
                d.documento.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
            );
        });
    }

    obterLojasParceiras() {
        this.apiService.obterFornecedores().subscribe((res: Fornecedor[]) => {
            this.lojasParceiras = res;
            this.lojasParceirasToList = res;
            console.log(this.lojasParceiras);
        });
    }

    handleEditBtn(loja: Fornecedor) {
        this.apiService.obterFornecedor(loja).subscribe((res: Fornecedor) => {
            console.log(res);
            if (!res.endereco) {
                res.endereco = {};
            }

            if (!res.fornecedorBase) {
                res.fornecedorBase = {};
            }

            this.loja = res;

            this.open();
        });
    }

    async buscaCep(e) {
        const cep = e.target.value.replace(/[^\w\s]/gi, '');
        if (cep.length < 7) return;
        const { bairro, complemento, localidade, logradouro, uf } = await this.http
            .get<any>(`https://viacep.com.br/ws/${cep}/json`)
            .toPromise();
        this.loja.endereco = {
            cep,
            logradouro,
            complemento,
            bairro,
            cidade: localidade,
            estado: uf,
        };
    }

    handleFormSubmit(form: NgForm) {
        this.loja.ativo = true;

        // this.loja.fornecedorBase = {
        //     host: form.value.host || '',
        //     password: form.value.password || '',
        //     port: form.value.port || '',
        //     serviceName: form.value.serviceName,
        //     userId: form.value.userId || '',
        // };

        if (this.loja.fornecedorBase.password === '') {
            delete this.loja.fornecedorBase.password;
        }
        if (this.loja.fornecedorBase.userId == '') {
            delete this.loja.fornecedorBase.userId;
        }

        if (this.loja.id) {
            this.editarLoja(form);
        } else {
            this.inserirLoja(form);
        }
    }

    inserirLoja(form: NgForm) {
        this.apiService.inserirFornecedor(this.loja).subscribe(
            res => {
                console.log(res);

                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Loja Criada com sucesso',
                    type: 'success',
                }).then(() => {
                    this.obterLojasParceiras();
                    form.resetForm();
                    this.loja = {
                        endereco: {},
                        fornecedorBase: {},
                        usuario: {},
                    };
                    this.modalService.dismissAll();
                });
            },
            err => {
                const errors = err.error.errors ? err.error.errors.concat('<br>') : '';
                Swal.fire({
                    title: 'Erro!',
                    html: 'Não foi possível criar a loja<br>' + errors,
                    type: 'error',
                }).then(() => {
                    console.log('teste');
                });
            },
        );
    }

    editarLoja(form: NgForm) {
        this.apiService.atualizarFornecedor(this.loja).subscribe(
            res => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Loja Atualizada com sucesso',
                    type: 'success',
                }).then(() => {
                    this.obterLojasParceiras();
                    form.resetForm();
                    this.loja = {
                        endereco: {},
                        fornecedorBase: {},
                    };
                    this.modalService.dismissAll();
                });
            },
            err => {
                console.log(err);
                if (err.error) {
                    const errors = err.error.errors.concat('<br>');
                    Swal.fire({
                        title: 'Erro!',
                        html: 'Não foi possível atualizar a loja<br>' + errors,
                        type: 'error',
                    }).then(() => {});
                } else {
                    Swal.fire({
                        title: 'Sucesso!',
                        text: 'Loja Atualizada com sucesso',
                        type: 'success',
                    }).then(() => {
                        this.obterLojasParceiras();
                        form.resetForm();
                        this.loja = { endereco: {} };
                        this.modalService.dismissAll();
                    });
                }
            },
        );
    }

    deletarLoja(loja: Fornecedor) {
        Swal.fire({
            title: 'Você tem certeza?',
            text: 'Ao confirmar os dados não poderão ser recuperados!',
            type: 'question',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
        }).then(confirm => {
            if (confirm.value == true) {
                this.apiService.deletarFornecedor(loja).subscribe(
                    res => {
                        this.obterLojasParceiras();
                        Swal.fire({
                            title: 'Sucesso!',
                            text: 'Loja Deletada com sucesso',
                            type: 'success',
                        });
                    },
                    err => {
                        Swal.fire({
                            title: 'Erro!',
                            text: 'Não foi possível deletar a loja',
                            type: 'error',
                        });
                    },
                );
            }
        });
    }
}
