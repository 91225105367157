import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Produto } from 'src/models/produto';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-produtos',
    templateUrl: './produtos.component.html',
    styleUrls: ['./produtos.component.scss'],
})
export class ProdutosComponent implements OnInit {
    produtos: Produto[] = [];
    page: number;
    pageSize: number;
    quantidadeTotal: number;
    searchTerm: string;
    @ViewChild('content', { static: true }) content: ElementRef;
    @ViewChild('modalDetails', { static: true }) modalDetails: ElementRef;
    produto: Produto;
    produtoToShow: Produto;
    filtro: any = {};

    constructor(public apiService: ApiService, private spinner: NgxSpinnerService, private modalService: NgbModal) {}

    ngOnInit() {
        this.obterProdutos();
    }

    open() {
        this.modalService.open(this.content, { size: 'lg' }).result.then(
            result => {
                console.log(`Closed with: ${result}`);
            },
            reason => {
                console.log(reason);
            },
        );
    }

    showDetails(produto: Produto) {
        this.apiService.obterProduto(produto.id).subscribe((res: Produto) => {
            this.produtoToShow = res;
            this.modalService.open(this.modalDetails, { size: 'sm' }).result.then(
                result => {
                    console.log('fechado');
                },
                reason => {
                    console.log(reason);
                },
            );
        });
    }

    obterProdutos(searching = false) {
        if (searching) {
            this.page = 1;
        }
        this.spinner.show();

        this.apiService.obterProdutos(this.page, this.filtro).subscribe(
            (res: {
                produtos: Produto[];
                paginaAtual: number;
                quantidadeTotal: number;
                quantidadeTotalPagina: number;
            }) => {
                console.log(res);
                this.page = res.paginaAtual;
                this.pageSize = res.produtos.length;
                this.quantidadeTotal = res.quantidadeTotal;
                this.produtos = res.produtos.map(p => {
                    if (p.imagem != '') {
                        p.imagem = 'data:image/jpeg;base64,' + p.imagem;
                    }
                    return p;
                });
                this.spinner.hide();
            },
            null,
            () => this.spinner.hide(),
        );
    }

    handleEditBtn(produto) {
        this.produto = produto;

        this.open();
    }

    atualizaProduto(form) {
        this.produto.promocao = Boolean(this.produto.promocao);
        console.log(this.produto);
        this.apiService.atualizarProduto(this.produto).subscribe(
            res => {
                console.log(res);
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Produto Atualizado com sucesso',
                    type: 'success',
                }).then(() => {
                    this.obterProdutos();
                    form.resetForm();
                    this.produto = null;
                    this.modalService.dismissAll();
                });
            },
            err => {
                const errors = err.error.errors ? err.error.errors.concat('<br>') : '';

                Swal.fire({
                    title: 'Erro!',
                    html: 'Não foi possível atualizar o produto<br>' + errors,
                    type: 'error',
                }).then(() => {});
            },
        );
    }
}
