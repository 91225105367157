import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-administracao',
    templateUrl: './administracao.component.html',
    styleUrls: ['./administracao.component.scss'],
})
export class AdministracaoComponent implements OnInit {
    page: number;
    pageSize: number;
    quantidadeTotal: number;
    usuarios: any[] = [];
    usuario: any = {};
    searchTermProfissional: string = '';
    searchTermUsuario: string = '';
    quantidadeTotalPontosProfissionais: any;
    quantidadeTotalProfissionais: any;
    profissionais: any[] = [];
    pageUsuario: number;
    pageSizeUsuario: number;
    quantidadeTotalUsuario: number;
    @ViewChild('modalUsuario', { static: true }) modalUsuario: ElementRef;
    pageProfissional: number;
    pageProfissionalSize: number;
    quantidadeProfissionalTotal: number;

    constructor(public api: ApiService, private modalService: NgbModal) {}

    ngOnInit() {
        this.obterProfissionais();
        this.obterUsuarios();
        this.obterGraficos();
    }

    open() {
        console.log('open');
        this.modalService.open(this.modalUsuario, { size: 'lg' }).result.then(
            result => {
                console.log(`Closed with: ${result}`);
            },
            reason => {
                console.log(reason);
            },
        );
    }

    criaUsuario(form: NgForm) {
        this.api.inserirUsuario(this.usuario).subscribe(
            res => {
                console.log(res);

                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Usuário Criado com sucesso',
                    type: 'success',
                }).then(() => {
                    this.obterUsuarios();
                    form.resetForm();
                    this.usuario = {};
                    this.modalService.dismissAll();
                });
            },
            err => {
                let errors = err.error.errors ? err.error.errors.concat('<br>') : '';
                Swal.fire({
                    title: 'Erro!',
                    html: 'Não foi possível criar usuário<br>' + errors,
                    type: 'error',
                }).then(() => {
                    console.log('teste');
                });
            },
        );
    }

    obterGraficos() {
        this.api.obterGrafico().subscribe((res: any) => {
            this.quantidadeTotalPontosProfissionais = res[0].quantidadeTotalPontosProfissionais;
            this.quantidadeTotalProfissionais = res[0].quantidadeTotalProfissionais;
        });
    }

    obterProfissionais(searching = false) {
        if (searching) {
            this.pageProfissional = 1;
        }
        this.api
            .obterProfissionais(this.pageProfissional, this.searchTermProfissional)
            .subscribe(
                (res: {
                    usuarios: any[];
                    paginaAtual: number;
                    quantidadeTotal: number;
                    quantidadeTotalPagina: number;
                }) => {
                  console.log(res);
                    this.pageProfissional = res.paginaAtual;
                    this.pageProfissionalSize = res.usuarios.length;
                    this.quantidadeProfissionalTotal = res.quantidadeTotal;
                    this.profissionais = res.usuarios;
                },
            );
    }

    obterUsuarios(searching = false) {
        if (searching) {
            this.pageUsuario = 1;
        }
        this.api
            .obterUsuarios(this.pageUsuario, this.searchTermUsuario)
            .subscribe(
                (res: {
                    usuarios: any[];
                    paginaAtual: number;
                    quantidadeTotal: number;
                    quantidadeTotalPagina: number;
                }) => {
                    this.pageUsuario = res.paginaAtual;
                    this.pageSizeUsuario = res.usuarios.length;
                    this.quantidadeTotalUsuario = res.quantidadeTotal;
                    this.usuarios = res.usuarios;
                },
            );
    }
}
