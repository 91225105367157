import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    usuario: any = {};
    errors: Array<string>;
    constructor(public router: Router, public auth: AuthService) {}

    entrar() {
        this.errors = [];
        this.auth.fazerLogin(this.usuario).subscribe(
            res => {
                this.auth.setToken(res.accessToken);
                this.router.navigate(['/sistema']);
            },
            err => {
                this.errors = err.error.errors;
            },
        );
    }
}
