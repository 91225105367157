/* eslint-disable @typescript-eslint/camelcase */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
export const TOKEN_NAME = 'accessToken';
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    url = 'https://api.masteratacadista.elti.com.br/api';

    constructor(private http: HttpClient) {}

    fazerLogin(dadosLogin: { cpf: string; password: string }) {
        return this.http.post(`${this.url}/Auth/web/signIn`, dadosLogin, {}).pipe(map((result: any) => result.data));
    }

    recuperaSenha(email) {
        return this.http.post(`${this.url}/Auth/ForgotPassword`, {
            email,
        });
    }

    deslogar() {
        localStorage.clear();
    }

    token() {
        return localStorage.getItem(TOKEN_NAME);
    }

    setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
    }

    getUser() {
        return jwt_decode(localStorage.getItem(TOKEN_NAME));
    }

    getTokenExpirationDate(): Date {
        const decoded = jwt_decode(localStorage.getItem(TOKEN_NAME));

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);

        return date;
    }

    userIsAuthenticated(token?: string): boolean {
        if (!token) {
            token = this.token();
        }
        if (!token) return false;

        const date = this.getTokenExpirationDate();

        if (date === undefined) return false;

        return date.valueOf() > new Date().valueOf();
    }
}
