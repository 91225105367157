import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Fornecedor } from 'src/models/fornecedor';
import { AuthService } from './auth.service';
import { Produto } from 'src/models/produto';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    url = 'https://api.masteratacadista.elti.com.br/api';

    constructor(private http: HttpClient, public auth: AuthService) {}

    obterHeaders(): HttpHeaders {
        const header = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        });

        return header;
    }

    inserirFornecedor(dados: Fornecedor) {
        return this.http.post(`${this.url}/fornecedor`, dados, {
            headers: this.obterHeaders(),
        });
    }

    inserirUsuario(dados: any) {
        const { FornecedorId } = this.auth.getUser();
        dados.fornecedorId = Number(FornecedorId);
        return this.http.post(`${this.url}/auth/web/register`, dados, {
            headers: this.obterHeaders(),
        });
    }

    atualizarFornecedor(fornecedor: Fornecedor) {
        if (this.isEmpty(fornecedor.fornecedorBase)) {
            delete fornecedor.fornecedorBase;
        }
        return this.http.put(`${this.url}/fornecedor/${fornecedor.id}`, fornecedor, {
            headers: this.obterHeaders(),
        });
    }
    atualizarProduto(produto: Produto) {
        return this.http.put(`${this.url}/produtos/${produto.id}`, produto, {
            headers: this.obterHeaders(),
        });
    }

    deletarFornecedor(fornecedor: Fornecedor) {
        return this.http
            .delete(`${this.url}/fornecedor/${fornecedor.id}`, {
                headers: this.obterHeaders(),
            })
            .pipe(map((result: any) => result.data));
    }

    obterFornecedores() {
        return this.http.get(`${this.url}/fornecedor`, {
            headers: this.obterHeaders(),
        });
    }

    isEmpty(obj) {
        return !obj || Object.keys(obj).length === 0;
    }
    obterFornecedor(fornecedor: Fornecedor) {
        return this.http.get(`${this.url}/fornecedor/${fornecedor.id}`, {
            headers: this.obterHeaders(),
        });
    }

    obterProdutos(page = 1, filtro = {}) {
        return this.http.get(`${this.url}/produtos/fornecedor?id=${this.auth.getUser().FornecedorId}&pagina=${page}`, {
            headers: this.obterHeaders(),
            params: filtro,
        });
    }

    importarProdutos(codigos: Array<string>) {
        const importacaoDTO = { fornecedorId: Number(this.auth.getUser().FornecedorId), codigos };
        return this.http.post(`${this.url}/produtos/fornecedor/importar`, importacaoDTO, {
            headers: this.obterHeaders(),
        });
    }

    obterProdutosParaImportacao(page = 1, filtro = {}) {
        return this.http.get(
            `${this.url}/produtos/fornecedor/view?id=${this.auth.getUser().FornecedorId}&pagina=${page}`,
            {
                headers: this.obterHeaders(),
                params: filtro,
            },
        );
    }
    obterProduto(id) {
        return this.http.get(`${this.url}/produtos/${id}`, { headers: this.obterHeaders() });
    }

    obterProfissionais(pagina = 1, query = '') {
        const { FornecedorId } = this.auth.getUser();

        return this.http.get(`${this.url}/usuario/fornecedor?id=${FornecedorId}&pagina=${pagina}&q=${query}`, {
            headers: this.obterHeaders(),
        });
    }

    obterUsuarios(pagina = 1, query = '') {
        const { FornecedorId } = this.auth.getUser();

        return this.http.get(`${this.url}/usuario/fornecedor/sistema?id=${FornecedorId}&pagina=${pagina}&q=${query}`, {
            headers: this.obterHeaders(),
        });
    }

    criarNotificacao(dados) {
        return this.http.post(`${this.url}/notificacao`, dados, {
            headers: this.obterHeaders(),
        });
    }

    processarNotificacao(dados) {
        return this.http.post(`${this.url}/usuario/filtrar`, dados, { headers: this.obterHeaders() });
    }
    criarNotificacaoFornecedor(dados: any) {
        return this.http.post(`${this.url}/notificacao/fornecedor/${dados.fornecedorId}`, dados, {
            headers: this.obterHeaders(),
        });
    }

    obterNotificacoes(pagina = 1) {
        const { FornecedorId } = this.auth.getUser();
        return this.http.get(`${this.url}/notificacao/fornecedor/${FornecedorId}`, {
            headers: this.obterHeaders(),
        });
    }

    obterCompras(page = 1, filtro) {
        const { FornecedorId } = this.auth.getUser();

        return this.http.get(
            `${this.url}/venda/fornecedor?id=${FornecedorId}&pagina=${page}&cpf=${filtro.cpf}&data=${filtro.data}`,
            {
                headers: this.obterHeaders(),
            },
        );
    }

    obterCompra(id) {
        return this.http.get(`${this.url}/venda/${id}`, {
            headers: this.obterHeaders(),
        });
    }

    obterProfissoes() {
        return this.http.get(`${this.url}/usuario/profissoes`, { headers: this.obterHeaders() });
    }
    obterCidades() {
        return this.http.get(`${this.url}/usuario/cidades`, { headers: this.obterHeaders() });
    }
    obterGrafico() {
        return this.http.get(`${this.url}/usuario/grafico/totais`, { headers: this.obterHeaders() });
    }

    obterPromocoes() {
        const { FornecedorId } = this.auth.getUser();

        return this.http.get(`${this.url}/carousel/fornecedor/web/${FornecedorId}`, { headers: this.obterHeaders() });
    }

    obterMarcas(page = 1) {
        const { FornecedorId } = this.auth.getUser();

        return this.http.get(`${this.url}/marca?pagina=${page}`, { headers: this.obterHeaders() });
    }

    atualizarMarca(dados) {
        return this.http.put(`${this.url}/marca/${dados.id}`, dados, {
            headers: this.obterHeaders(),
        });
    }

    deletarMarca(marca) {
        return this.http
            .delete(`${this.url}/marca/${marca.id}`, {
                headers: this.obterHeaders(),
            })
            .pipe(map((result: any) => result.data));
    }

    criarPromocao(dados) {
        return this.http.post(`${this.url}/Carousel`, dados, {
            headers: this.obterHeaders(),
        });
    }

    atualizarPromocao(dados) {
        return this.http.put(`${this.url}/Carousel/${dados.id}`, dados, {
            headers: this.obterHeaders(),
        });
    }
}
