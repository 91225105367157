import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-sistema',
    templateUrl: './sistema.component.html',
})
export class SistemaComponent implements OnInit {
    toggleMenu: any;
    constructor() {}

    ngOnInit() {}

    toggle(data) {
        this.toggleMenu = data;
    }
}
