import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Fornecedor } from 'src/models/fornecedor';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Promocao } from 'src/models/Promocao';

import Compressor from 'compressorjs';

@Component({
    selector: 'app-promocoes',
    templateUrl: './promocoes.component.html',
    styleUrls: ['./promocoes.component.scss'],
})
export class PromocoesComponent implements OnInit {
    mostraFormPush = false;
    promocao: any = {
        ativo: true,
    };

    promocoes: Promocao[];
    image: any;
    mostraFormPromocoes: boolean;

    constructor(public api: ApiService) {}

    ngOnInit() {
        this.obterPromocoes();
    }

    obterPromocoes() {
        this.api.obterPromocoes().subscribe((res: Promocao[]) => {
            this.promocoes = res.map(p => {
                if (p.imagem != '') {
                    p.imagem = 'data:image/jpeg;base64,' + p.imagem;
                }
                return p;
            });
        });
    }

    async changeListener($event): Promise<void> {
        const base64: string = await this.readThis($event.target);
        this.image = base64;
        this.promocao.imagem = base64.split(',')[1];
    }

    readThis(inputValue: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const file: File = inputValue.files[0];
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    const reader = new FileReader();
                    reader.readAsDataURL(result);
                    reader.onloadend = function() {
                        resolve(reader.result);
                    };
                },
                error(err) {
                    reject(err.message);
                },
            });
        });
    }

    isEmpty(obj) {
        return !obj || Object.keys(obj).length === 0;
    }

    handleEditBtn(promocao) {
        console.log(promocao);
        this.promocao = promocao;
        this.image = this.promocao.imagem;
        this.abrirFormPromocoes();
    }
    handlePromocoesForm(form: NgForm) {
        const dados = this.promocao;

        console.log(dados);

        if (this.promocao.id) {
            this.atualizarPromocao(form);
        } else {
            this.criarPromocao(form);
        }
    }

    criarPromocao(form: NgForm) {
        this.promocao.ativo = true;
        this.api.criarPromocao(this.promocao).subscribe(
            () => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Promoção cadastrada com sucesso',
                    type: 'success',
                }).then(() => {
                    this.obterPromocoes();
                    this.fecharFormPromocoes();
                    form.resetForm();
                    this.promocao = {};
                });
            },
            err => {
                const errors = err.error.errors ? err.error.errors.concat('<br>') : '';
                Swal.fire({
                    title: 'Erro!',
                    html: 'Não foi possível cadastrar a promoção<br>' + errors,
                    type: 'error',
                }).then(() => {});
            },
        );
    }

    atualizarPromocao(form: NgForm) {
        this.promocao.imagem = this.image.split(',')[1];

        this.api.atualizarPromocao(this.promocao).subscribe(
            () => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Promoção atualizada com sucesso',
                    type: 'success',
                }).then(() => {
                    this.obterPromocoes();
                    this.fecharFormPromocoes();
                    form.resetForm();
                    this.promocao = {};
                });
            },
            err => {
                const errors = err.error.errors ? err.error.errors.concat('<br>') : '';
                Swal.fire({
                    title: 'Erro!',
                    html: 'Não foi possível atualizar a promoção<br>' + errors,
                    type: 'error',
                }).then(() => {});
            },
        );
    }

    abrirFormPromocoes() {
        this.mostraFormPromocoes = true;
    }
    fecharFormPromocoes() {
        this.mostraFormPromocoes = false;
    }
}
