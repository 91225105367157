import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { SistemaModule } from './pages/sistema/sistema.module';
import { RecuperaSenhaComponent } from './pages/auth/recupera-senha/recupera-senha.component';

const appRoutes: Routes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'recupera-senha',
        component: RecuperaSenhaComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes), SistemaModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
