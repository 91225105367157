import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "multi-select",
  templateUrl: "./multi-select.component.html",
  styleUrls: ["./multi-select.component.scss"]
})
export class MultiSelectComponent implements OnInit {
  @Input() text: string = "Selecione";
  @Input() dropdownList: Array<any>;
  selectedItems: any;
  @Output() selected = new EventEmitter();
  dropdownSettings = {};
  labelKey: string | number = "valor";
  primaryKey: string = "chave";

  constructor() {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      text: this.text,
      enableCheckAll: true,
      selectAllText: "Marcar Todos",
      unSelectAllText: "Desmarcar Todos",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      searchPlaceholderText: "Buscar",
      labelKey: this.labelKey,
      primaryKey: this.primaryKey,
      noDataLabel: "Nenhum dado encontrado",
      badgeShowLimit: 1
    };
  }
  onItemSelect(item: any) {
    this.selected.emit(this.selectedItems);
  }
}
