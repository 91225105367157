import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
    selector: 'app-recupera-senha',
    templateUrl: './recupera-senha.component.html',
    styleUrls: ['./recupera-senha.component.scss'],
})
export class RecuperaSenhaComponent implements OnInit {
    constructor(public auth: AuthService, public route: Router) {}

    ngOnInit() {}

    recuperaSenha(email) {
        this.auth.recuperaSenha(email).subscribe(res => {
            Swal.fire({
                title: 'Recuperação de Senha!',
                text: 'Um link de recuperação foi enviado para seu e-mail!',
                type: 'success',
            }).then(() => {
                this.route.navigate(['../']);
            });
        });
    }
}
