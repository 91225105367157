import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Fornecedor } from 'src/models/fornecedor';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Notificacao } from 'src/models/notificacao';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-midia',
    templateUrl: './midia.component.html',
    styleUrls: ['./midia.component.scss'],
})
export class MidiaComponent implements OnInit {
    mostraFormPush = false;
    push: any = {};
    pontos: any = {};
    fornecedores: Fornecedor[] = [];
    notificacoes: Notificacao[];
    image: any;
    profissoes: any;
    cidades: any;
    selectedCidades: any;
    selectedProfissoes: any;
    notificacaoToSend;
    @ViewChild('content', { static: true }) content: ElementRef;
    @ViewChild('pushForm', { static: false }) pushForm: NgForm;
    closeResult: string;
    usuariosPushNotification = [];

    constructor(public api: ApiService, private modalService: NgbModal) {}

    open(tipo = null) {
        this.modalService.open(this.content, { size: 'lg' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
        this.obterFornecedores();
        this.obterNotificacoes();
        this.api.obterProfissoes().subscribe((res: Array<any>) => {
            this.profissoes = res.map((p, i) => {
                return { valor: p, chave: i };
            });
        });
        this.api.obterCidades().subscribe((res: Array<any>) => {
            this.cidades = res.map((p, i) => {
                return { valor: p, chave: i };
            });
        });
        this.api.obterGrafico().subscribe(res => {
            console.log(res);
        });
    }

    obterNotificacoes() {
        this.api.obterNotificacoes().subscribe((res: Notificacao[]) => {
            this.notificacoes = res;
        });
    }

    selecionouProfissoes(e) {
        this.selectedProfissoes = e.map(p => p.valor);
    }
    selecionouCidades(e) {
        this.selectedCidades = e.map(p => p.valor);
    }

    obterFornecedores() {
        this.api.obterFornecedores().subscribe((res: Fornecedor[]) => {
            this.fornecedores = res;
        });
    }
    changeListener($event): void {
        this.readThis($event.target);
    }

    readThis(inputValue: any): void {
        const file: File = inputValue.files[0];
        const myReader: FileReader = new FileReader();

        myReader.onloadend = e => {
            this.image = myReader.result;
        };
        myReader.readAsDataURL(file);
    }

    isEmpty(obj) {
        return !obj || Object.keys(obj).length === 0;
    }
    handlePushForm() {
        if (this.image) {
            this.push.imagemUpload = this.image.split(',')[1];
        }
        const dados = this.push;
        if (!this.isEmpty(this.pontos)) {
            dados.pontos = this.pontos;
        }

        dados.cidades = this.selectedCidades;
        dados.profissoes = this.selectedProfissoes;
        this.notificacaoToSend = dados;

        this.api.processarNotificacao(dados).subscribe((res: Array<any>) => {
            this.usuariosPushNotification = res;

            this.open();
        });
    }
    FormataStringData(date) {
        const splited = date.split('-');
        return `${splited[2]}/${splited[1]}/${splited[0]}`;
    }

    fecharModal() {
        this.modalService.dismissAll();
        // this.pushForm.resetForm();
        // this.selectedProfissoes = [];
        // this.notificacaoToSend = {};
        // this.selectedCidades = [];
    }

    criarNotificacao() {
        const dados = this.notificacaoToSend;
        dados.fornecedorId = parseInt(dados.fornecedorId);
        this.api.criarNotificacao(dados).subscribe(
            () => {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Notificação enviada com sucesso',
                    type: 'success',
                }).then(() => {
                    this.obterNotificacoes();
                    this.fecharFormPush();
                    this.notificacaoToSend = {};
                    this.modalService.dismissAll();
                    this.pushForm.resetForm();
                    this.push = {};
                });
            },
            err => {
                const errors = err.error.errors ? err.error.errors.concat('<br>') : '';
                Swal.fire({
                    title: 'Erro!',
                    html: 'Não foi possível enviar a notificação<br>' + errors,
                    type: 'error',
                }).then(() => {});
            },
        );
    }

    abrirFormPush() {
        this.mostraFormPush = true;
    }
    fecharFormPush() {
        this.mostraFormPush = false;
    }
}
