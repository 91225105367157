import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as jspdf from 'jspdf';

import html2canvas from 'html2canvas';
@Component({
    selector: 'app-compras',
    templateUrl: './compras.component.html',
    styleUrls: ['./compras.component.scss'],
})
export class ComprasComponent implements OnInit {
    page: number;
    pageSize: any;
    quantidadeTotal: any;
    vendas: any = [];
    filtro: any = {
        cpf: '',
        data: '',
    };
    @ViewChild('modalDetails', { static: true }) modalDetails: ElementRef;

    compraToShow: any;
    constructor(public api: ApiService, private spinner: NgxSpinnerService, private modalService: NgbModal) {}

    ngOnInit() {
        this.obterCompras();
    }

    generatePDF() {
        var data = document.getElementById('contentToConvert');
        html2canvas(data).then(canvas => {
            // Few necessary setting options
            var imgWidth = 208;
            var pageHeight = 295;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;

            const contentDataURL = canvas.toDataURL('image/png');
            let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            pdf.save('historico_compras.pdf'); // Generated PDF
        });
    }

    showDetails(venda) {
        this.api.obterCompra(venda.id).subscribe(res => {
            this.compraToShow = res;
            this.modalService.open(this.modalDetails, { size: 'lg' }).result.then(
                result => {
                    console.log('fechado');
                },
                reason => {
                    console.log(reason);
                },
            );
        });
        // console.log(venda);
    }

    obterCompras(searching = false) {
        if (searching) {
            this.page = 1;
        }
        console.log(this.filtro);
        this.api.obterCompras(this.page, this.filtro).subscribe(
            (res: { vendas: any[]; paginaAtual: number; quantidadeTotal: number; quantidadeTotalPagina: number }) => {
                console.log(res);
                this.page = res.paginaAtual;
                this.pageSize = res.vendas.length;
                this.quantidadeTotal = res.quantidadeTotal;
                this.vendas = res.vendas;
                this.spinner.hide();
            },
            null,
            () => this.spinner.hide(),
        );
    }
}
