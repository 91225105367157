import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SistemaComponent } from './sistema.component';
import { SidebarComponent } from './navigation/sidebar/sidebar.component';
import { TopbarComponent } from './navigation/topbar/topbar.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { LojasComponent } from './lojas/lojas.component';
import { NgbModalModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MidiaComponent } from './midia/midia.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { ComprasComponent } from './compras/compras.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgxBrModule } from '@nbfontana/ngx-br';
import { MultiSelectComponent } from 'src/app/components/multi-select/multi-select.component';
import { AdministracaoComponent } from './administracao/administracao.component';
import { PromocoesComponent } from './promocoes/promocoes.component';
import { NgxMaskModule } from 'ngx-mask';
import { ProdutosImportacaoComponent } from './produtos/produtos-importacao/produtos-importacao.component';
import { MarcasComponent } from './marcas/marcas.component';
const routes: Routes = [
    {
        path: 'sistema',
        component: SistemaComponent,
        children: [
            {
                path: '',
                component: AdministracaoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'lojas',
                component: LojasComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'midia',
                component: MidiaComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'promocoes',
                component: PromocoesComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'marcas',
                component: MarcasComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'produtos',
                component: ProdutosComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'produtos/importar',
                component: ProdutosImportacaoComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'compras',
                component: ComprasComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'administracao',
                component: AdministracaoComponent,
                canActivate: [AuthGuard],
            },
            { path: '**', component: AdministracaoComponent, canActivate: [AuthGuard] },
        ],
    },
];

@NgModule({
    declarations: [
        SistemaComponent,
        DashboardComponent,
        SidebarComponent,
        TopbarComponent,
        FooterComponent,
        LojasComponent,
        MidiaComponent,
        PromocoesComponent,
        MarcasComponent,
        ProdutosComponent,
        ComprasComponent,
        MultiSelectComponent,
        AdministracaoComponent,
        ProdutosImportacaoComponent,
    ],
    imports: [
        CommonModule,
        NgbPaginationModule,
        RouterModule.forChild(routes),
        FormsModule,
        NgbModalModule,
        AngularMultiSelectModule,
        NgxBrModule.forRoot(),
        NgxMaskModule.forRoot(),
    ],
})
export class SistemaModule {}
