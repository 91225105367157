import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
    toggleMenu = false;
    @Output() toggleSideBar = new EventEmitter();
    constructor() {}
    toggle() {
        if (this.toggleMenu) {
            this.toggleMenu = false;
        } else {
            this.toggleMenu = true;
        }
        this.toggleSideBar.emit(this.toggleMenu);
    }

    ngOnInit() {}
}
