import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/api.service';
import { Produto } from 'src/models/produto';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-produtos-importacao',
    templateUrl: './produtos-importacao.component.html',
    styleUrls: ['./produtos-importacao.component.scss'],
})
export class ProdutosImportacaoComponent implements OnInit {
    produtos: Produto[] = [];
    page: number;
    pageSize: number;
    quantidadeTotal: number;
    produtosParaImportar: Array<string> = [];
    searchTerm: string;
    filtro: any = {};
    constructor(public apiService: ApiService, private router: Router, private spinner: NgxSpinnerService) {}

    ngOnInit() {
        this.obterProdutos();
    }

    estaMarcado(id): boolean {
        return !!this.produtosParaImportar.find(p => p === id);
    }

    marcarParaImportacao(id: string) {
        if (this.estaMarcado(id)) {
            this.produtosParaImportar = this.produtosParaImportar.filter(p => p !== id);
        } else {
            this.produtosParaImportar.push(id);
        }
    }

    importar() {
        this.spinner.show();
        this.apiService.importarProdutos(this.produtosParaImportar).subscribe(res => {
            Swal.fire({
                title: 'Sucesso!',
                text: 'Produtos Importados com sucesso',
                type: 'success',
            }).then(() => {
                console.log('chegou');
                this.router.navigate(['sistema', 'produtos']);
                this.spinner.hide();
            });
        });
    }
    obterProdutos(searching = false) {
        if (searching) {
            this.page = 1;
        }
        this.spinner.show();

        this.apiService.obterProdutosParaImportacao(this.page, this.filtro).subscribe(
            (res: {
                produtos: Produto[];
                paginaAtual: number;
                quantidadeTotal: number;
                quantidadeTotalPagina: number;
            }) => {
                console.log(res);
                this.page = res.paginaAtual;
                this.pageSize = res.produtos.length;
                this.quantidadeTotal = res.quantidadeTotal;
                this.produtos = res.produtos.map(p => {
                    if (p.imagem != '') {
                        p.imagem = 'data:image/jpeg;base64,' + p.imagem;
                    }
                    return p;
                });
                this.spinner.hide();
            },
            () => this.spinner.hide(),
        );
    }
}
