import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SistemaModule } from './pages/sistema/sistema.module';
import { RecuperaSenhaComponent } from './pages/auth/recupera-senha/recupera-senha.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthGuard } from './guards/auth.guard';
import { LoaderInterceptor } from './interceptors/loader.intercepetor';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);
@NgModule({
    declarations: [AppComponent, LoginComponent, RecuperaSenhaComponent],
    imports: [BrowserModule, FormsModule, HttpClientModule, AppRoutingModule, SistemaModule, NgxSpinnerModule],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
